import { callApi } from '@/helpers/api';
import CONFIG from '@/config/global';

const {
  api: {
    HOST, GET_EPISODES_BY_CHANNEL_ID, GET_EPISODES_BY_CHANNEL_ID_AND_SEASON
  }
} = CONFIG;

// Get a list of episode previews for a channel.
// If no season is specified, fetch episodes for all seasons
// If a season is specified, fetch episodes only for that season
const getEpisodePreviewsByChannelId = ({
  channelId, seasonNumber = null, isServer = false
}) => {
  return callApi({
    url: seasonNumber ? (
      HOST(GET_EPISODES_BY_CHANNEL_ID_AND_SEASON)
        + GET_EPISODES_BY_CHANNEL_ID_AND_SEASON
          .replace(':channel_id', channelId)
          .replace(':season', seasonNumber)
    ) : (
      HOST(GET_EPISODES_BY_CHANNEL_ID)
        + GET_EPISODES_BY_CHANNEL_ID.replace(':channel_id', channelId)
    ),
    isServer
  });
};

export default getEpisodePreviewsByChannelId;