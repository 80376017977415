import { useCallback, useMemo } from 'react';
import { filter } from '@/helpers/lodash';
import useQuery from '@/react-query/common/hooks/useQuery';
import { QK_EPISODES_BY_SHOW } from '@/config/query-keys';
import getEpisodePreviewsByChannelId from '@/helpers/api/episodes/getEpisodePreviewsByChannelId';
import { orderEpisodesForShow } from '@/helpers/pages/channel';
import { formatEpisode } from '@/helpers/common';
import { isEqualAsNumber, truthyOrZero } from '@/helpers/math';

export const createEpisodesByShowQueryKey = ({ id } = {}) => [QK_EPISODES_BY_SHOW, { id }];

const useEpisodesByShow = ({
  enabled = true,
  id,
  seasonNumber
} = {}) => {
  const fetchEpisodes = useCallback(() => getEpisodePreviewsByChannelId({ channelId: id }), [id]);

  const results = useQuery({
    queryKey: createEpisodesByShowQueryKey({ id }),
    queryFn: fetchEpisodes,
    enabled: enabled && !!id,
    staleTime: 1000 * 60 * 30 // 30 minutes
  });

  const { data } = results;

  const {
    json,
    notFound,
    status: statusCode
  } = data || {};

  const { nodes: episodes } = json || {};

  const episodesSorted = useMemo(() => orderEpisodesForShow({ episodes: (episodes || []).map((ep) => formatEpisode(ep)) }), [episodes]);
  const episodesBySeason = useMemo(
    () => (
      truthyOrZero(seasonNumber)
        ? filter(episodesSorted, (e) => isEqualAsNumber(e.season, seasonNumber))
        : episodesSorted
    ),
    [episodesSorted, seasonNumber]
  );

  return {
    ...results,
    notFound,
    statusCode,
    episodes: episodesBySeason
  };
};

export default useEpisodesByShow;