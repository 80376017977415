import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  nextTrack,
  pauseAudio,
  playAudio,
  playEpisode,
  playEpisodeFromCustomQueue,
  playEpisodeFromTracklist,
  previousTrack,
  seekAudio,
  setVolume,
  toggleMuted
} from '@/state/audio/actions';
import { selectAudioPaused } from '@/state/selectors/audio';
import useShakaPlayer from '@/hooks/audio/useShakaPlayer';

const selector = (state) => ({ paused: selectAudioPaused(state) });

const useAudioReduxControls = () => {
  const dispatch = useDispatch();

  const { paused } = useSelector(selector);

  const { checkAndHandleInitialPlayInteraction } = useShakaPlayer();

  const handlePlay = useCallback(() => {
    checkAndHandleInitialPlayInteraction();

    dispatch(playAudio());
  }, [dispatch, checkAndHandleInitialPlayInteraction]);

  const handlePause = useCallback(() => {
    checkAndHandleInitialPlayInteraction();

    dispatch(pauseAudio());
  }, [dispatch, checkAndHandleInitialPlayInteraction]);

  const handlePlayOrPause = useCallback(() => (paused ? handlePlay() : handlePause()), [paused, handlePlay, handlePause]);

  const handlePlayEpisode = useCallback(async ({
    episode,
    seekPosition,
    streamingContext
  }) => {
    checkAndHandleInitialPlayInteraction();

    const episodeId = episode.id || {};
    const { show } = episode;
    const showId = show.id;

    if (episodeId && showId) {
      dispatch(playEpisode({
        episode,
        streamingContext,
        seek: seekPosition || 0
      }));
    }
  }, [dispatch, checkAndHandleInitialPlayInteraction]);

  const handlePlayEpisodeFromTracklist = useCallback(async ({ id, streamingContext }) => {
    checkAndHandleInitialPlayInteraction();

    if (id) {
      dispatch(playEpisodeFromTracklist({
        id,
        streamingContext
      }));
    }
  }, [dispatch, checkAndHandleInitialPlayInteraction]);

  const handlePlayEpisodeFromCustomQueue = useCallback(async ({ key, id }) => {
    checkAndHandleInitialPlayInteraction();

    if (key && id) {
      dispatch(playEpisodeFromCustomQueue({
        key,
        id
      }));
    }
  }, [dispatch, checkAndHandleInitialPlayInteraction]);

  const handleNextTrack = useCallback(async () => {
    checkAndHandleInitialPlayInteraction();

    dispatch(nextTrack());
  }, [dispatch, checkAndHandleInitialPlayInteraction]);

  const handlePreviousTrack = useCallback(async () => {
    checkAndHandleInitialPlayInteraction();

    dispatch(previousTrack());
  }, [dispatch, checkAndHandleInitialPlayInteraction]);

  const handleSaveSeek = useCallback((seek) => {
    dispatch(seekAudio({ seek }));
  }, [dispatch]);

  const handleSaveVolume = useCallback((volume) => {
    dispatch(setVolume(volume));
  }, [dispatch]);

  const handleMute = useCallback((isMuted) => {
    dispatch(toggleMuted(isMuted));
  }, [dispatch]);

  return {
    handlePlay,
    handlePause,
    handlePlayOrPause,
    handlePlayEpisode,
    handlePlayEpisodeFromTracklist,
    handlePlayEpisodeFromCustomQueue,
    handleNextTrack,
    handlePreviousTrack,
    handleMute,
    handleSaveVolume,
    handleSaveSeek
  };
};

export default useAudioReduxControls;