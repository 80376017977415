import {
  shallowEqual, useDispatch, useSelector
} from 'react-redux';
import { useCallback } from 'react';
import { setLibraryAudioQueueSearchKey } from '@/state/library/actions';
import { setQueueMode } from '@/state/audio/actions';
import {
  selectAudioPaused,
  selectCurrentEpisodeId,
  selectKeakiePlaybackMode
} from '@/state/selectors/audio';
import useAudioReduxControls from '@/hooks/audio/useAudioReduxControls';
import { streamingContextTypes } from '@/config/events-keakie';

const selector = (state) => ({
  paused: selectAudioPaused(state),
  currentEpisodeId: selectCurrentEpisodeId(state),
  keakiePlaybackMode: selectKeakiePlaybackMode(state)
});

const useAudioReduxControlsCustom = ({ streamingContext = null } = {}) => {
  const dispatch = useDispatch();

  const {
    paused,
    currentEpisodeId,
    keakiePlaybackMode
  } = useSelector(selector, shallowEqual);

  const {
    handlePlayEpisode,
    handlePlayEpisodeFromTracklist: handlePlayFromTracklist,
    handlePlayEpisodeFromCustomQueue: handlePlayFromCustomQueue,
    handlePreviousTrack,
    handleNextTrack,
    handlePlay,
    handlePause
  } = useAudioReduxControls();

  const computeContext = useCallback((defaultContext) => (streamingContext || defaultContext), [streamingContext]);

  const handleSetQueueMode = useCallback(({ mode }) => {
    dispatch(setQueueMode({ mode }));
  }, [dispatch]);

  const handlePlayEpisodeFromShow = useCallback(({
    episode,
    wasClickedDirectly = false,
    seekPosition = null
  }) => {
    const isShowMode = keakiePlaybackMode === 'show';

    if (!isShowMode) {
      handleSetQueueMode({ mode: 'show' });
    }

    if ((episode.id !== currentEpisodeId) || !isShowMode) {
      handlePlayEpisode({
        seekPosition,
        streamingContext: computeContext(
          wasClickedDirectly
            ? streamingContextTypes.DIRECT_EPISODE_URL
            : streamingContextTypes.SHOW
        ),
        episode
      });
    } else if (paused) {
      handlePlay();
    } else {
      handlePause();
    }
  }, [
    handleSetQueueMode,
    handlePlay,
    handlePause,
    handlePlayEpisode,
    computeContext,
    keakiePlaybackMode,
    paused,
    currentEpisodeId
  ]);

  const handlePlayEpisodeFromLibrary = useCallback(({
    episode,
    seekPosition = null,
    searchKey
  }) => {
    const isLibraryMode = keakiePlaybackMode === 'library';

    if (!isLibraryMode) {
      handleSetQueueMode({ mode: 'library' });
    }

    if ((episode.id !== currentEpisodeId) || !isLibraryMode) {
      dispatch(setLibraryAudioQueueSearchKey(searchKey));
      handlePlayEpisode({
        seekPosition,
        streamingContext: computeContext(streamingContextTypes.LIBRARY),
        episode
      });
    } else if (paused) {
      handlePlay();
    } else {
      handlePause();
    }
  }, [
    handleSetQueueMode,
    handlePlay,
    handlePause,
    handlePlayEpisode,
    computeContext,
    dispatch,
    keakiePlaybackMode,
    paused,
    currentEpisodeId
  ]);

  const handlePlayEpisodeFromTracklist = useCallback(({ id }) => {
    handlePlayFromTracklist({
      id,
      streamingContext: computeContext(keakiePlaybackMode === 'show'
        ? streamingContextTypes.QUEUED_EPISODE
        : streamingContextTypes.QUEUED_EPISODE)
    });
  }, [handlePlayFromTracklist, computeContext, keakiePlaybackMode]);

  const handlePlayEpisodeFromCustomQueue = useCallback(({ key, id }) => {
    handlePlayFromCustomQueue({
      key,
      id
    });
  }, [handlePlayFromCustomQueue]);

  return {
    handlePlayEpisodeFromShow,
    handlePlayEpisodeFromLibrary,
    handlePlayEpisodeFromTracklist,
    handlePlayEpisodeFromCustomQueue,
    handlePreviousTrack,
    handleNextTrack
  };
};

export default useAudioReduxControlsCustom;