import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { classes } from '@/helpers/styling';

const DEFAULT_ROOT_MARGIN = '33% 0% 33% 0%';

const useIntersectionObserver = ({
  // react-intersection-observer options
  rootMargin,
  root,
  threshold,
  delay,
  // Additional params
  triggerInViewOnceOnly = true,
  onEnterView = undefined,
  onLeaveView = undefined,
  disable = false
} = {}) => {
  const [hasEnteredViewOnce, setHasEnteredViewOnce] = useState(false);

  const [ref, inView] = useInView({
    root,
    threshold,
    delay,
    rootMargin: rootMargin || DEFAULT_ROOT_MARGIN,
    skip: disable,
    triggerOnce: triggerInViewOnceOnly
  });

  useEffect(() => {
    if (inView) {
      if (!hasEnteredViewOnce) {
        setHasEnteredViewOnce(true);
      }

      if (onEnterView && (!triggerInViewOnceOnly || !hasEnteredViewOnce)) {
        onEnterView();
      }
    } else if (!inView && onLeaveView && hasEnteredViewOnce) {
      onLeaveView();
    }
  }, [hasEnteredViewOnce, inView, onEnterView, onLeaveView, triggerInViewOnceOnly]);

  let isInView = triggerInViewOnceOnly
    ? hasEnteredViewOnce
    : inView;

  if (disable) {
    isInView = true;
  }

  return {
    ref,
    screenIntersectionClassName: classes(
      {
        '--offscreen-hide': !isInView,
        '--onscreen-show': isInView
      }
    ),
    inView: isInView,
    hasEnteredViewOnce: disable ? true : hasEnteredViewOnce
  };
};

export default useIntersectionObserver;